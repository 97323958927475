import React from "react";


class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li className="active">
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/woordenlijst/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/lexique/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="e7b765b91f328d5b3e82a920ea07b39044882760"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/infomateriaal-diaree/">Infomateriaal</a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/woordenlijst/"> Woordenlijst diarree</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Deze video laat zien hoe Imodium<sup>®</sup> helpt om je
                  darmen terug in hun natuurlijke balans te brengen.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Als het gaat om de behandeling van de symptomen van diarree,
                  zijn er nog veel onzekerheden. Lees enkele tips.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-41">
            <h1>Woordenlijst diarree</h1>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/abdominaal/">Abdominaal</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Met betrekking tot het abdomen. Ook bekend als de buik, met
                  name het gedeelte van het lichaam tussen de borstkas en het
                  bekken. Het abdomen bevat organen zoals de maag, de dunne
                  darm, de dikke darm, het rectum en de blaas.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/acute-diarree/">Acute diarree</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Acute diarree is een plotse aanval van diarree die minder dan
                  twee weken duurt.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/allergie/">Allergie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een reactie op vreemde substanties door het immuunsysteem van
                  het lichaam. Substanties die een allergie uitlokken, worden
                  allergenen genoemd, bijvoorbeeld pollen, stofmijten en
                  bepaalde voedingsmiddelen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/antibiotica/">Antibiotica</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Geneesmiddelen die worden gebruikt voor de behandeling van
                  infecties veroorzaakt door schadelijke bacteriën en andere
                  micro-organismen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/antispasmodisch-middel/">
                    Antispasmodisch middel
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>Een geneesmiddel dat spierkrampen vermindert of voorkomt.</p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/bacterien/">Bacteriën</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Ook microben genoemd; deze eencellige micro-organismen worden
                  vaak in verband gebracht met infecties, maar sommige zijn
                  onschadelijk en/of gunstig voor de mens.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/centraal-zenuwstelsel/">
                    Centraal zenuwstelsel
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Het centraal zenuwstelsel (CZS) is het gedeelte van het
                  zenuwstelsel dat bestaat uit de hersenen en de ruggengraat.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/chronische-diarree/">
                    Chronische diarree
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Men spreekt van chronische diarree wanneer de symptomen langer
                  dan vier weken aanhouden.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/constipatie/">Constipatie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Niet frequente, en vaak onvolledige, darmbewegingen; het
                  tegenovergestelde van diarree.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/darmen/">Darmen</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Deel van het spijsverteringsstelsel, bestaande uit de dunne en
                  de dikke darm, die in het abdomen liggen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/dehydratatie/">Dehydratatie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Wanneer het lichaam overmatig veel water verliest. Langdurig
                  braken of diarree kunnen leiden tot dehydratatie, evenals
                  blootstelling aan hitte, langdurige zware fysieke
                  inspanningen, en een aantal geneesmiddelen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/diarree/">Diarree</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Abnormaal losse of vloeibare stoelgang die meer dan drie keer
                  per dag voorkomt, en/of stoelgang met een volume van meer dan
                  200g per dag.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/flatulentie-of-winderigheid/">
                    Flatulentie of winderigheid
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Overmatige gasvorming in de darmen. 14 keer gas uitdrijven in
                  een periode van 24 uur wordt als ‘normaal’ beschouwd.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/gastro-enteritis-of-buikgriep/">
                    Gastro-enteritis
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een ontsteking van de maag en de darmen, die misselijkheid,
                  braken en/of diarree kan veroorzaken. Virussen,
                  voedselvergiftiging en stress kunnen allemaal gastro-enteritis
                  veroorzaken.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/immuunsysteem/">Immuunsysteem</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Het natuurlijke verdedigingssysteem van ons lichaam. Het
                  herkent vreemde substanties en beschermt ons tegen infecties.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/laxeermiddelen/">Laxeermiddelen</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Chemische stoffen of geneesmiddelen die de darmen soepeler
                  maken om constipatie tegen te gaan; kunnen diarree veroorzaken
                  bij overmatig gebruik.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/loperamide/">Loperamide</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een geneesmiddel dat wordt gebruikt bij de behandeling van
                  diarree door de contractie van de spieren in de darm te
                  vertragen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/menstruatie/">Menstruatie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Periodieke bloeding uit de baarmoeder, die ongeveer om de 4
                  weken optreedt, en aldus voor de menstruele cyclus zorgt.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/misselijkheid/">Misselijkheid</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Dit is een aandrang om te braken, die kan worden veroorzaakt
                  door veel dingen, zoals ziekte, geneesmiddelen en onevenwicht
                  in het binnenoor.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/norovirus/">Norovirus</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Er zijn eigenlijk veel soorten norovirussen; samen maken ze
                  deel uit van een groep virussen die de meest voorkomende
                  oorzaak van gastro-enteritis zijn. Hoewel ze mensen het hele
                  jaar door kunnen besmetten, wordt ze vaak 'winter vomiting
                  disease' genoemd omdat ze het meest voorkomt in de winter.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/nutrienten-of-voedingsstoffen/">
                    Nutriënten of voedingsstoffen
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Substanties uit het voedsel die het lichaam nodig heeft voor
                  energie, groei en herstel, zoals vitamines, mineralen,
                  koolhydraten, vetten en eiwitten.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/opgeblazen-gevoel/">
                    Opgeblazen gevoel
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een opgeblazen gevoel wordt vaak in verband gebracht met het
                  abdomen, wanneer darmgassen zorgen voor een vol en gespannen
                  gevoel van het abdomen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/peristaltische-beweging/">
                    Peristaltische beweging
                  </a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  De beweging van de spieren in onze darmen die samentrekken en
                  ontspannen in ritmische golven, waardoor de inhoud van de darm
                  wordt voortgeduwd. Bij deze beweging wordt voedsel ook gemengd
                  met maagzuren, waardoor het een dunne vloeistof wordt.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/rehydratatie/">Rehydratatie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Het proces van het verloren water opnieuw in het lichaam te
                  brengen, ofwel door vloeistoffen te drinken, ofwel intraveneus
                  (rechtstreeks in de aderen).
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/sigmoidoscopie/">Sigmoïdoscopie</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een onderzoek van het rectum en de karteldarm, aan de hand van
                  een dunne, verlichte buis, een sigmoïdoscoop, waarmee weefsel
                  of cellen kunnen worden verzameld voor verder onderzoek.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/simeticon/">Simeticon</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een geneesmiddel dat helpt gassen op te lossen die voor
                  winderigheid zorgen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/slijm/">Slijm</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een dikke, glibberige vloeistof die wordt geproduceerd door
                  membranen rond bepaalde organen, zoals de neus, de mond, de
                  keel en de vagina.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/stoelgang/">Stoelgang</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  De vaste afvalmaterie die door het rectum gaat in de vorm van
                  darmbewegingen. Stoelgang bestaat uit onverteerd voedsel,
                  bacteriën, slijm en dode cellen.
                </p>
                <p />
              </div>
            </div>
            <div className="row">
              <div className="four columns">
                <h4>
                  <a href="/woordenlijst/symmptoom/">Symptoom</a>
                </h4>
              </div>
              <div className="eight columns">
                <p />
                <p>
                  Een fysiek of mentaal teken dat iemand een aandoening of
                  ziekte heeft. Diarree, hoofdpijn, koorts, vermoeidheid,
                  misselijkheid, pijn en depressie zijn veel voorkomende
                  symptomen.
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
        <footer class="footer"
style={{"height": "501.006px"}}>
            <div class="row">
                <div class="three columns" style={{
                
              }}>
                    <ul>
                        <li class="first last" >
                        <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
              
                        <ul>
                        <li class="first last">
                        <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide
                        </a>
                        </li>
                        </ul>
                        </li>
                    </ul>

                    <ul>
                        <li class="first last" >
                        <a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
              
                          <ul>
                          <li class="first">
                          <a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant
                          </a></li>
                          <li>
                            <a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo
                          </a></li>
                          <li class="last">
                            <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules
                          </a></li>
                          
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last" >
                        <a href="/feiten-over-diarree/">Feiten over diarree</a>
              
                        <ul>
                        <li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
                        <li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
                        <li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
                        <li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
                        <li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
                        <li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
                        <li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
                        <li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
                        <li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>                        
                    </ul>
                    <ul>
                        <li class="first last" ><a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                          <li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
                          <li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
                          <li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" >
                    
                    <ul>
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>
                        <li><a href="/infomateriaal-diaree/">Infomateriaal</a></li>
                        <li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
                        <li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
                            <li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
                            <li><a href="/privacybeleid/">Privacybeleid</a></li>
                            <li><a href="/cookiebeleid/">Cookiebeleid</a></li>
                            <li class="last"><a href="/contact/">Contact</a></li>
                            <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Antwerpseweg 15-17, B-2340 Beerse. Dossiernr: BE-IM-2200031.
                        </p>
                   </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
